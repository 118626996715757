export default {
  countries: [
    {
      name_pl: 'Afganistan',
      code: 'AF',
    }, {
      name_pl: 'Albania',
      code: 'AL',
    }, {
      name_pl: 'Algieria',
      code: 'DZ',
    }, {
      name_pl: 'Andora',
      code: 'AD',
    }, {
      name_pl: 'Angola',
      code: 'AO',
    }, {
      name_pl: 'Anguilla',
      code: 'AI',
    }, {
      name_pl: 'Antarktyka',
      code: 'AQ',
    }, {
      name_pl: 'Antigua i Barbuda',
      code: 'AG',
    }, {
      name_pl: 'Arabia Saudyjska',
      code: 'SA',
    }, {
      name_pl: 'Argentyna',
      code: 'AR',
    }, {
      name_pl: 'Armenia',
      code: 'AM',
    }, {
      name_pl: 'Aruba',
      code: 'AW',
    }, {
      name_pl: 'Australia',
      code: 'AU',
    }, {
      name_pl: 'Austria',
      code: 'AT',
    }, {
      name_pl: 'Azerbejdżan',
      code: 'AZ',
    }, {
      name_pl: 'Bahamy',
      code: 'BS',
    }, {
      name_pl: 'Bahrajn',
      code: 'BH',
    }, {
      name_pl: 'Bangladesz',
      code: 'BD',
    }, {
      name_pl: 'Barbados',
      code: 'BB',
    }, {
      name_pl: 'Belgia',
      code: 'BE',
    }, {
      name_pl: 'Belize',
      code: 'BZ',
    }, {
      name_pl: 'Benin',
      code: 'BJ',
    }, {
      name_pl: 'Bermudy',
      code: 'BM',
    }, {
      name_pl: 'Bhutan',
      code: 'BT',
    }, {
      name_pl: 'Białoruś',
      code: 'BY',
    }, {
      name_pl: 'Boliwia',
      code: 'BO',
    }, {
      name_pl: 'Bonaire, Sint Eustatius i Saba',
      code: 'BQ',
    }, {
      name_pl: 'Bośnia i Hercegowina',
      code: 'BA',
    }, {
      name_pl: 'Botswana',
      code: 'BW',
    }, {
      name_pl: 'Brazylia',
      code: 'BR',
    }, {
      name_pl: 'Brunei',
      code: 'BN',
    }, {
      name_pl: 'Brytyjskie Terytorium Oceanu Indyjskiego',
      code: 'IO',
    }, {
      name_pl: 'Brytyjskie Wyspy Dziewicze',
      code: 'VG',
    }, {
      name_pl: 'Bułgaria',
      code: 'BG',
    }, {
      name_pl: 'Burkina Faso',
      code: 'BF',
    }, {
      name_pl: 'Burundi',
      code: 'BI',
    }, {
      name_pl: 'Chile',
      code: 'CL',
    }, {
      name_pl: 'Chiny',
      code: 'CN',
    }, {
      name_pl: 'Chorwacja',
      code: 'HR',
    }, {
      name_pl: 'Curaçao',
      code: 'CW',
    }, {
      name_pl: 'Cypr',
      code: 'CY',
    }, {
      name_pl: 'Czad',
      code: 'TD',
    }, {
      name_pl: 'Czarnogóra',
      code: 'ME',
    }, {
      name_pl: 'Czechy',
      code: 'CZ',
    }, {
      name_pl: 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych',
      code: 'UM',
    }, {
      name_pl: 'Dania',
      code: 'DK',
    }, {
      name_pl: 'Demokratyczna Republika Konga',
      code: 'CD',
    }, {
      name_pl: 'Dominika',
      code: 'DM',
    }, {
      name_pl: 'Dominikana',
      code: 'DO',
    }, {
      name_pl: 'Dżibuti',
      code: 'DJ',
    }, {
      name_pl: 'Egipt',
      code: 'EG',
    }, {
      name_pl: 'Ekwador',
      code: 'EC',
    }, {
      name_pl: 'Erytrea',
      code: 'ER',
    }, {
      name_pl: 'Estonia',
      code: 'EE',
    }, {
      name_pl: 'Etiopia',
      code: 'ET',
    }, {
      name_pl: 'Falklandy',
      code: 'FK',
    }, {
      name_pl: 'Fidżi',
      code: 'FJ',
    }, {
      name_pl: 'Filipiny',
      code: 'PH',
    }, {
      name_pl: 'Finlandia',
      code: 'FI',
    }, {
      name_pl: 'Francja',
      code: 'FR',
    }, {
      name_pl: 'Francuskie Terytoria Południowe i Antarktyczne',
      code: 'TF',
    }, {
      name_pl: 'Gabon',
      code: 'GA',
    }, {
      name_pl: 'Gambia',
      code: 'GM',
    }, {
      name_pl: 'Georgia Południowa i Sandwich Południowy',
      code: 'GS',
    }, {
      name_pl: 'Ghana',
      code: 'GH',
    }, {
      name_pl: 'Gibraltar',
      code: 'GI',
    }, {
      name_pl: 'Grecja',
      code: 'GR',
    }, {
      name_pl: 'Grenada',
      code: 'GD',
    }, {
      name_pl: 'Grenlandia',
      code: 'GL',
    }, {
      name_pl: 'Gruzja',
      code: 'GE',
    }, {
      name_pl: 'Guam',
      code: 'GU',
    }, {
      name_pl: 'Guernsey',
      code: 'GG',
    }, {
      name_pl: 'Gujana Francuska',
      code: 'GF',
    }, {
      name_pl: 'Gujana',
      code: 'GY',
    }, {
      name_pl: 'Gwadelupa',
      code: 'GP',
    }, {
      name_pl: 'Gwatemala',
      code: 'GT',
    }, {
      name_pl: 'Gwinea Bissau',
      code: 'GW',
    }, {
      name_pl: 'Gwinea Równikowa',
      code: 'GQ',
    }, {
      name_pl: 'Gwinea',
      code: 'GN',
    }, {
      name_pl: 'Haiti',
      code: 'HT',
    }, {
      name_pl: 'Hiszpania',
      code: 'ES',
    }, {
      name_pl: 'Holandia',
      code: 'NL',
    }, {
      name_pl: 'Honduras',
      code: 'HN',
    }, {
      name_pl: 'Hongkong',
      code: 'HK',
    }, {
      name_pl: 'Indie',
      code: 'IN',
    }, {
      name_pl: 'Indonezja',
      code: 'ID',
    }, {
      name_pl: 'Irak',
      code: 'IQ',
    }, {
      name_pl: 'Iran',
      code: 'IR',
    }, {
      name_pl: 'Irlandia',
      code: 'IE',
    }, {
      name_pl: 'Islandia',
      code: 'IS',
    }, {
      name_pl: 'Izrael',
      code: 'IL',
    }, {
      name_pl: 'Jamajka',
      code: 'JM',
    }, {
      name_pl: 'Japonia',
      code: 'JP',
    }, {
      name_pl: 'Jemen',
      code: 'YE',
    }, {
      name_pl: 'Jersey',
      code: 'JE',
    }, {
      name_pl: 'Jordania',
      code: 'JO',
    }, {
      name_pl: 'Kajmany',
      code: 'KY',
    }, {
      name_pl: 'Kambodża',
      code: 'KH',
    }, {
      name_pl: 'Kamerun',
      code: 'CM',
    }, {
      name_pl: 'Kanada',
      code: 'CA',
    }, {
      name_pl: 'Katar',
      code: 'QA',
    }, {
      name_pl: 'Kazachstan',
      code: 'KZ',
    }, {
      name_pl: 'Kenia',
      code: 'KE',
    }, {
      name_pl: 'Kirgistan',
      code: 'KG',
    }, {
      name_pl: 'Kiribati',
      code: 'KI',
    }, {
      name_pl: 'Kolumbia',
      code: 'CO',
    }, {
      name_pl: 'Komory',
      code: 'KM',
    }, {
      name_pl: 'Kongo',
      code: 'CG',
    }, {
      name_pl: 'Korea Południowa',
      code: 'KR',
    }, {
      name_pl: 'Korea Północna',
      code: 'KP',
    }, {
      name_pl: 'Kostaryka',
      code: 'CR',
    }, {
      name_pl: 'Kuba',
      code: 'CU',
    }, {
      name_pl: 'Kuwejt',
      code: 'KW',
    }, {
      name_pl: 'Laos',
      code: 'LA',
    }, {
      name_pl: 'Lesotho',
      code: 'LS',
    }, {
      name_pl: 'Liban',
      code: 'LB',
    }, {
      name_pl: 'Liberia',
      code: 'LR',
    }, {
      name_pl: 'Libia',
      code: 'LY',
    }, {
      name_pl: 'Liechtenstein',
      code: 'LI',
    }, {
      name_pl: 'Litwa',
      code: 'LT',
    }, {
      name_pl: 'Luksemburg',
      code: 'LU',
    }, {
      name_pl: 'Łotwa',
      code: 'LV',
    }, {
      name_pl: 'Macedonia',
      code: 'MK',
    }, {
      name_pl: 'Madagaskar',
      code: 'MG',
    }, {
      name_pl: 'Majotta',
      code: 'YT',
    }, {
      name_pl: 'Makau',
      code: 'MO',
    }, {
      name_pl: 'Malawi',
      code: 'MW',
    }, {
      name_pl: 'Malediwy',
      code: 'MV',
    }, {
      name_pl: 'Malezja',
      code: 'MY',
    }, {
      name_pl: 'Mali',
      code: 'ML',
    }, {
      name_pl: 'Malta',
      code: 'MT',
    }, {
      name_pl: 'Mariany Północne',
      code: 'MP',
    }, {
      name_pl: 'Maroko',
      code: 'MA',
    }, {
      name_pl: 'Martynika',
      code: 'MQ',
    }, {
      name_pl: 'Mauretania',
      code: 'MR',
    }, {
      name_pl: 'Mauritius',
      code: 'MU',
    }, {
      name_pl: 'Meksyk',
      code: 'MX',
    }, {
      name_pl: 'Mikronezja',
      code: 'FM',
    }, {
      name_pl: 'Mjanma',
      code: 'MM',
    }, {
      name_pl: 'Mołdawia',
      code: 'MD',
    }, {
      name_pl: 'Monako',
      code: 'MC',
    }, {
      name_pl: 'Mongolia',
      code: 'MN',
    }, {
      name_pl: 'Montserrat',
      code: 'MS',
    }, {
      name_pl: 'Mozambik',
      code: 'MZ',
    }, {
      name_pl: 'Namibia',
      code: 'NA',
    }, {
      name_pl: 'Nauru',
      code: 'NR',
    }, {
      name_pl: 'Nepal',
      code: 'NP',
    }, {
      name_pl: 'Niemcy',
      code: 'DE',
    }, {
      name_pl: 'Niger',
      code: 'NE',
    }, {
      name_pl: 'Nigeria',
      code: 'NG',
    }, {
      name_pl: 'Nikaragua',
      code: 'NI',
    }, {
      name_pl: 'Niue',
      code: 'NU',
    }, {
      name_pl: 'Norfolk',
      code: 'NF',
    }, {
      name_pl: 'Norwegia',
      code: 'NO',
    }, {
      name_pl: 'Nowa Kaledonia',
      code: 'NC',
    }, {
      name_pl: 'Nowa Zelandia',
      code: 'NZ',
    }, {
      name_pl: 'Oman',
      code: 'OM',
    }, {
      name_pl: 'Pakistan',
      code: 'PK',
    }, {
      name_pl: 'Palau',
      code: 'PW',
    }, {
      name_pl: 'Palestyna',
      code: 'PS',
    }, {
      name_pl: 'Panama',
      code: 'PA',
    }, {
      name_pl: 'Papua-Nowa Gwinea',
      code: 'PG',
    }, {
      name_pl: 'Paragwaj',
      code: 'PY',
    }, {
      name_pl: 'Peru',
      code: 'PE',
    }, {
      name_pl: 'Pitcairn',
      code: 'PN',
    }, {
      name_pl: 'Polinezja Francuska',
      code: 'PF',
    }, {
      name_pl: 'Polska',
      code: 'PL',
    }, {
      name_pl: 'Portoryko',
      code: 'PR',
    }, {
      name_pl: 'Portugalia',
      code: 'PT',
    }, {
      name_pl: 'Republika Południowej Afryki',
      code: 'ZA',
    }, {
      name_pl: 'Republika Środkowoafrykańska',
      code: 'CF',
    }, {
      name_pl: 'Republika Zielonego Przylądka',
      code: 'CV',
    }, {
      name_pl: 'Reunion',
      code: 'RE',
    }, {
      name_pl: 'Rosja',
      code: 'RU',
    }, {
      name_pl: 'Rumunia',
      code: 'RO',
    }, {
      name_pl: 'Rwanda',
      code: 'RW',
    }, {
      name_pl: 'Sahara Zachodnia',
      code: 'EH',
    }, {
      name_pl: 'Saint Kitts i Nevis',
      code: 'KN',
    }, {
      name_pl: 'Saint Lucia',
      code: 'LC',
    }, {
      name_pl: 'Saint Vincent i Grenadyny',
      code: 'VC',
    }, {
      name_pl: 'Saint-Barthélemy',
      code: 'BL',
    }, {
      name_pl: 'Saint-Martin',
      code: 'MF',
    }, {
      name_pl: 'Saint-Pierre i Miquelon',
      code: 'PM',
    }, {
      name_pl: 'Salwador',
      code: 'SV',
    }, {
      name_pl: 'Samoa Amerykańskie',
      code: 'AS',
    }, {
      name_pl: 'Samoa',
      code: 'WS',
    }, {
      name_pl: 'San Marino',
      code: 'SM',
    }, {
      name_pl: 'Senegal',
      code: 'SN',
    }, {
      name_pl: 'Serbia',
      code: 'RS',
    }, {
      name_pl: 'Seszele',
      code: 'SC',
    }, {
      name_pl: 'Sierra Leone',
      code: 'SL',
    }, {
      name_pl: 'Singapur',
      code: 'SG',
    }, {
      name_pl: 'Sint Maarten',
      code: 'SX',
    }, {
      name_pl: 'Słowacja',
      code: 'SK',
    }, {
      name_pl: 'Słowenia',
      code: 'SI',
    }, {
      name_pl: 'Somalia',
      code: 'SO',
    }, {
      name_pl: 'Sri Lanka',
      code: 'LK',
    }, {
      name_pl: 'Stany Zjednoczone',
      code: 'US',
    }, {
      name_pl: 'Suazi',
      code: 'SZ',
    }, {
      name_pl: 'Sudan',
      code: 'SD',
    }, {
      name_pl: 'Sudan Południowy',
      code: 'SS',
    }, {
      name_pl: 'Surinam',
      code: 'SR',
    }, {
      name_pl: 'Svalbard i Jan Mayen',
      code: 'SJ',
    }, {
      name_pl: 'Syria',
      code: 'SY',
    }, {
      name_pl: 'Szwajcaria',
      code: 'CH',
    }, {
      name_pl: 'Szwecja',
      code: 'SE',
    }, {
      name_pl: 'Tadżykistan',
      code: 'TJ',
    }, {
      name_pl: 'Tajlandia',
      code: 'TH',
    }, {
      name_pl: 'Tajwan',
      code: 'TW',
    }, {
      name_pl: 'Tanzania',
      code: 'TZ',
    }, {
      name_pl: 'Timor Wschodni',
      code: 'TL',
    }, {
      name_pl: 'Togo',
      code: 'TG',
    }, {
      name_pl: 'Tokelau',
      code: 'TK',
    }, {
      name_pl: 'Tonga',
      code: 'TO',
    }, {
      name_pl: 'Trynidad i Tobago',
      code: 'TT',
    }, {
      name_pl: 'Tunezja',
      code: 'TN',
    }, {
      name_pl: 'Turcja',
      code: 'TR',
    }, {
      name_pl: 'Turkmenistan',
      code: 'TM',
    }, {
      name_pl: 'Turks i Caicos',
      code: 'TC',
    }, {
      name_pl: 'Tuvalu',
      code: 'TV',
    }, {
      name_pl: 'Uganda',
      code: 'UG',
    }, {
      name_pl: 'Ukraina',
      code: 'UA',
    }, {
      name_pl: 'Urugwaj',
      code: 'UY',
    }, {
      name_pl: 'Uzbekistan',
      code: 'UZ',
    }, {
      name_pl: 'Vanuatu',
      code: 'VU',
    }, {
      name_pl: 'Wallis i Futuna',
      code: 'WF',
    }, {
      name_pl: 'Watykan',
      code: 'VA',
    }, {
      name_pl: 'Wenezuela',
      code: 'VE',
    }, {
      name_pl: 'Węgry',
      code: 'HU',
    }, {
      name_pl: 'Wielka Brytania',
      code: 'GB',
    }, {
      name_pl: 'Wietnam',
      code: 'VN',
    }, {
      name_pl: 'Włochy',
      code: 'IT',
    }, {
      name_pl: 'Wybrzeże Kości Słoniowej',
      code: 'CI',
    }, {
      name_pl: 'Wyspa Bouveta',
      code: 'BV',
    }, {
      name_pl: 'Wyspa Bożego Narodzenia',
      code: 'CX',
    }, {
      name_pl: 'Wyspa Man',
      code: 'IM',
    }, {
      name_pl: 'Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha',
      code: 'SH',
    }, {
      name_pl: 'Wyspy Alandzkie',
      code: 'AX',
    }, {
      name_pl: 'Wyspy Cooka',
      code: 'CK',
    }, {
      name_pl: 'Wyspy Dziewicze Stanów Zjednoczonych',
      code: 'VI',
    }, {
      name_pl: 'Wyspy Heard i McDonalda',
      code: 'HM',
    }, {
      name_pl: 'Wyspy Kokosowe',
      code: 'CC',
    }, {
      name_pl: 'Wyspy Marshalla',
      code: 'MH',
    }, {
      name_pl: 'Wyspy Owcze',
      code: 'FO',
    }, {
      name_pl: 'Wyspy Salomona',
      code: 'SB',
    }, {
      name_pl: 'Wyspy Świętego Tomasza i Książęca',
      code: 'ST',
    }, {
      name_pl: 'Zambia',
      code: 'ZM',
    }, {
      name_pl: 'Zimbabwe',
      code: 'ZW',
    }, {
      name_pl: 'Zjednoczone Emiraty Arabskie',
      code: 'AE',
    },
  ],
  lands: [
    { id: 2, name: 'dolnośląskie', },
    { id: 4, name: 'kujawsko-pomorskie', },
    { id: 6, name: 'lubelskie', },
    { id: 8, name: 'lubuskie', },
    { id: 10, name: 'łódzkie', },
    { id: 12, name: 'małopolskie', },
    { id: 14, name: 'mazowieckie', },
    { id: 16, name: 'opolskie', },
    { id: 18, name: 'podkarpackie', },
    { id: 20, name: 'podlaskie', },
    { id: 22, name: 'pomorskie', },
    { id: 24, name: 'śląskie', },
    { id: 26, name: 'świętokrzyskie', },
    { id: 28, name: 'warmińsko-mazurskie', },
    { id: 30, name: 'wielkopolskie', },
    { id: 32, name: 'zachodniopomorskie', },
  ],
  districts: [
    { id: 1, name: 'bolesławiecki', landId: 2, },
    { id: 2, name: 'dzierżoniowski', landId: 2, },
    { id: 3, name: 'głogowski', landId: 2, },
    { id: 4, name: 'górowski', landId: 2, },
    { id: 5, name: 'jaworski', landId: 2, },
    { id: 6, name: 'jeleniogórski', landId: 2, },
    { id: 7, name: 'kamiennogórski', landId: 2, },
    { id: 8, name: 'kłodzki', landId: 2, },
    { id: 9, name: 'legnicki', landId: 2, },
    { id: 10, name: 'lubański', landId: 2, },
    { id: 11, name: 'lubiński', landId: 2, },
    { id: 12, name: 'lwówecki', landId: 2, },
    { id: 13, name: 'milicki', landId: 2, },
    { id: 14, name: 'oleśnicki', landId: 2, },
    { id: 15, name: 'oławski', landId: 2, },
    { id: 16, name: 'polkowicki', landId: 2, },
    { id: 17, name: 'strzeliński', landId: 2, },
    { id: 18, name: 'średzki', landId: 2, },
    { id: 19, name: 'świdnicki', landId: 2, },
    { id: 20, name: 'trzebnicki', landId: 2, },
    { id: 21, name: 'wałbrzyski', landId: 2, },
    { id: 22, name: 'wołowski', landId: 2, },
    { id: 23, name: 'wrocławski', landId: 2, },
    { id: 24, name: 'ząbkowicki', landId: 2, },
    { id: 25, name: 'zgorzelecki', landId: 2, },
    { id: 26, name: 'złotoryjski', landId: 2, },
    { id: 27, name: 'm. Jelenia Góra', landId: 2, },
    { id: 28, name: 'm. Legnica', landId: 2, },
    { id: 29, name: 'm. Wrocław', landId: 2, },
    { id: 30, name: 'aleksandrowski', landId: 4, },
    { id: 31, name: 'brodnicki', landId: 4, },
    { id: 32, name: 'bydgoski', landId: 4, },
    { id: 33, name: 'chełmiński', landId: 4, },
    { id: 34, name: 'golubsko-dobrzyński', landId: 4, },
    { id: 35, name: 'grudziądzki', landId: 4, },
    { id: 36, name: 'inowrocławski', landId: 4, },
    { id: 37, name: 'lipnowski', landId: 4, },
    { id: 38, name: 'mogileński', landId: 4, },
    { id: 39, name: 'nakielski', landId: 4, },
    { id: 40, name: 'radziejowski', landId: 4, },
    { id: 41, name: 'rypiński', landId: 4, },
    { id: 42, name: 'sępoleński', landId: 4, },
    { id: 43, name: 'świecki', landId: 4, },
    { id: 44, name: 'toruński', landId: 4, },
    { id: 45, name: 'tucholski', landId: 4, },
    { id: 46, name: 'wąbrzeski', landId: 4, },
    { id: 47, name: 'włocławski', landId: 4, },
    { id: 48, name: 'żniński', landId: 4, },
    { id: 49, name: 'm. Bydgoszcz', landId: 4, },
    { id: 50, name: 'm. Grudziądz', landId: 4, },
    { id: 51, name: 'm. Toruń', landId: 4, },
    { id: 52, name: 'Włocławek', landId: 4, },
    { id: 53, name: 'bialski', landId: 6, },
    { id: 54, name: 'biłgorajski', landId: 6, },
    { id: 55, name: 'chełmski', landId: 6, },
    { id: 56, name: 'hrubieszowski', landId: 6, },
    { id: 57, name: 'janowski', landId: 6, },
    { id: 58, name: 'krasnostawski', landId: 6, },
    { id: 59, name: 'kraśnicki', landId: 6, },
    { id: 60, name: 'lubartowski', landId: 6, },
    { id: 61, name: 'lubelski', landId: 6, },
    { id: 62, name: 'łęczyński', landId: 6, },
    { id: 63, name: 'łukowski', landId: 6, },
    { id: 64, name: 'opolski', landId: 6, },
    { id: 65, name: 'parczewski', landId: 6, },
    { id: 66, name: 'puławski', landId: 6, },
    { id: 67, name: 'radzyński', landId: 6, },
    { id: 68, name: 'rycki', landId: 6, },
    { id: 69, name: 'świdnicki', landId: 6, },
    { id: 70, name: 'tomaszowski', landId: 6, },
    { id: 71, name: 'włodawski', landId: 6, },
    { id: 72, name: 'zamojski', landId: 6, },
    { id: 73, name: 'm. Biała Podlaska', landId: 6, },
    { id: 74, name: 'm. Chełm', landId: 6, },
    { id: 75, name: 'm. Lublin', landId: 6, },
    { id: 76, name: 'm. Zamość', landId: 6, },
    { id: 77, name: 'gorzowski', landId: 8, },
    { id: 78, name: 'krośnieński', landId: 8, },
    { id: 79, name: 'międzyrzecki', landId: 8, },
    { id: 80, name: 'nowosolski', landId: 8, },
    { id: 81, name: 'słubicki', landId: 8, },
    { id: 82, name: 'strzelecko-drezdenecki', landId: 8, },
    { id: 83, name: 'sulęciński', landId: 8, },
    { id: 84, name: 'świebodziński', landId: 8, },
    { id: 85, name: 'zielonogórski', landId: 8, },
    { id: 86, name: 'żagański', landId: 8, },
    { id: 87, name: 'żarski', landId: 8, },
    { id: 88, name: 'wschowski', landId: 8, },
    { id: 89, name: 'm. Gorzów Wielkopolski', landId: 8, },
    { id: 90, name: 'm. Zielona Góra', landId: 8, },
    { id: 91, name: 'bełchatowski', landId: 10, },
    { id: 92, name: 'kutnowski', landId: 10, },
    { id: 93, name: 'łaski', landId: 10, },
    { id: 94, name: 'łęczycki', landId: 10, },
    { id: 95, name: 'łowicki', landId: 10, },
    { id: 96, name: 'łódzki wschodni', landId: 10, },
    { id: 97, name: 'opoczyński', landId: 10, },
    { id: 98, name: 'pabianicki', landId: 10, },
    { id: 99, name: 'pajęczański', landId: 10, },
    { id: 100, name: 'piotrkowski', landId: 10, },
    { id: 101, name: 'poddębicki', landId: 10, },
    { id: 102, name: 'radomszczański', landId: 10, },
    { id: 103, name: 'rawski', landId: 10, },
    { id: 104, name: 'sieradzki', landId: 10, },
    { id: 105, name: 'skierniewicki', landId: 10, },
    { id: 106, name: 'tomaszowski', landId: 10, },
    { id: 107, name: 'wieluński', landId: 10, },
    { id: 108, name: 'wieruszowski', landId: 10, },
    { id: 109, name: 'zduńskowolski', landId: 10, },
    { id: 110, name: 'zgierski', landId: 10, },
    { id: 111, name: 'brzeziński', landId: 10, },
    { id: 112, name: 'm. Łódź', landId: 10, },
    { id: 113, name: 'Piotrków Trybunalski', landId: 10, },
    { id: 114, name: 'Skierniewice', landId: 10, },
    { id: 115, name: 'bocheński', landId: 12, },
    { id: 116, name: 'brzeski', landId: 12, },
    { id: 117, name: 'chrzanowski', landId: 12, },
    { id: 118, name: 'dąbrowski', landId: 12, },
    { id: 119, name: 'gorlicki', landId: 12, },
    { id: 120, name: 'krakowski', landId: 12, },
    { id: 121, name: 'limanowski', landId: 12, },
    { id: 122, name: 'miechowski', landId: 12, },
    { id: 123, name: 'myślenicki', landId: 12, },
    { id: 124, name: 'nowosądecki', landId: 12, },
    { id: 125, name: 'nowotarski', landId: 12, },
    { id: 126, name: 'olkuski', landId: 12, },
    { id: 127, name: 'oświęcimski', landId: 12, },
    { id: 128, name: 'proszowicki', landId: 12, },
    { id: 129, name: 'suski', landId: 12, },
    { id: 130, name: 'tarnowski', landId: 12, },
    { id: 131, name: 'tatrzański', landId: 12, },
    { id: 132, name: 'wadowicki', landId: 12, },
    { id: 133, name: 'wielicki', landId: 12, },
    { id: 134, name: 'm. Kraków', landId: 12, },
    { id: 135, name: 'm. Nowy Sącz', landId: 12, },
    { id: 136, name: 'm. Tarnów', landId: 12, },
    { id: 137, name: 'białobrzeski', landId: 14, },
    { id: 138, name: 'ciechanowski', landId: 14, },
    { id: 139, name: 'garwoliński', landId: 14, },
    { id: 140, name: 'gostyniński', landId: 14, },
    { id: 141, name: 'grodziski', landId: 14, },
    { id: 142, name: 'grójecki', landId: 14, },
    { id: 143, name: 'kozienicki', landId: 14, },
    { id: 144, name: 'legionowski', landId: 14, },
    { id: 145, name: 'lipski', landId: 14, },
    { id: 146, name: 'łosicki', landId: 14, },
    { id: 147, name: 'makowski', landId: 14, },
    { id: 148, name: 'miński', landId: 14, },
    { id: 149, name: 'mławski', landId: 14, },
    { id: 150, name: 'nowodworski', landId: 14, },
    { id: 151, name: 'ostrołęcki', landId: 14, },
    { id: 152, name: 'ostrowski', landId: 14, },
    { id: 153, name: 'otwocki', landId: 14, },
    { id: 154, name: 'piaseczyński', landId: 14, },
    { id: 155, name: 'płocki', landId: 14, },
    { id: 156, name: 'płoński', landId: 14, },
    { id: 157, name: 'pruszkowski', landId: 14, },
    { id: 158, name: 'przasnyski', landId: 14, },
    { id: 159, name: 'przysuski', landId: 14, },
    { id: 160, name: 'pułtuski', landId: 14, },
    { id: 161, name: 'radomski', landId: 14, },
    { id: 162, name: 'siedlecki', landId: 14, },
    { id: 163, name: 'sierpecki', landId: 14, },
    { id: 164, name: 'sochaczewski', landId: 14, },
    { id: 165, name: 'sokołowski', landId: 14, },
    { id: 166, name: 'szydłowiecki', landId: 14, },
    { id: 167, name: 'warszawski zachodni', landId: 14, },
    { id: 168, name: 'węgrowski', landId: 14, },
    { id: 169, name: 'wołomiński', landId: 14, },
    { id: 170, name: 'wyszkowski', landId: 14, },
    { id: 171, name: 'zwoleński', landId: 14, },
    { id: 172, name: 'żuromiński', landId: 14, },
    { id: 173, name: 'żyrardowski', landId: 14, },
    { id: 174, name: 'Ostrołęka', landId: 14, },
    { id: 175, name: 'm. Płock', landId: 14, },
    { id: 176, name: 'm. Radom', landId: 14, },
    { id: 177, name: 'm. Siedlce', landId: 14, },
    { id: 178, name: 'm. Warszawa', landId: 14, },
    { id: 179, name: 'brzeski', landId: 16, },
    { id: 180, name: 'głubczycki', landId: 16, },
    { id: 181, name: 'kędzierzyńsko-kozielski', landId: 16, },
    { id: 182, name: 'kluczborski', landId: 16, },
    { id: 183, name: 'krapkowicki', landId: 16, },
    { id: 184, name: 'namysłowski', landId: 16, },
    { id: 185, name: 'nyski', landId: 16, },
    { id: 186, name: 'oleski', landId: 16, },
    { id: 187, name: 'opolski', landId: 16, },
    { id: 188, name: 'prudnicki', landId: 16, },
    { id: 189, name: 'strzelecki', landId: 16, },
    { id: 190, name: 'm. Opole', landId: 16, },
    { id: 191, name: 'bieszczadzki', landId: 18, },
    { id: 192, name: 'brzozowski', landId: 18, },
    { id: 193, name: 'dębicki', landId: 18, },
    { id: 194, name: 'jarosławski', landId: 18, },
    { id: 195, name: 'jasielski', landId: 18, },
    { id: 196, name: 'kolbuszowski', landId: 18, },
    { id: 197, name: 'krośnieński', landId: 18, },
    { id: 198, name: 'leżajski', landId: 18, },
    { id: 199, name: 'lubaczowski', landId: 18, },
    { id: 200, name: 'łańcucki', landId: 18, },
    { id: 201, name: 'mielecki', landId: 18, },
    { id: 202, name: 'niżański', landId: 18, },
    { id: 203, name: 'przemyski', landId: 18, },
    { id: 204, name: 'przeworski', landId: 18, },
    { id: 205, name: 'ropczycko-sędziszowski', landId: 18, },
    { id: 206, name: 'rzeszowski', landId: 18, },
    { id: 207, name: 'sanocki', landId: 18, },
    { id: 208, name: 'stalowowolski', landId: 18, },
    { id: 209, name: 'strzyżowski', landId: 18, },
    { id: 210, name: 'tarnobrzeski', landId: 18, },
    { id: 211, name: 'leski', landId: 18, },
    { id: 212, name: 'm. Krosno', landId: 18, },
    { id: 213, name: 'm. Przemyśl', landId: 18, },
    { id: 214, name: 'm. Rzeszów', landId: 18, },
    { id: 215, name: 'Tarnobrzeg', landId: 18, },
    { id: 216, name: 'augustowski', landId: 20, },
    { id: 217, name: 'białostocki', landId: 20, },
    { id: 218, name: 'bielski', landId: 20, },
    { id: 219, name: 'grajewski', landId: 20, },
    { id: 220, name: 'hajnowski', landId: 20, },
    { id: 221, name: 'kolneński', landId: 20, },
    { id: 222, name: 'łomżyński', landId: 20, },
    { id: 223, name: 'moniecki', landId: 20, },
    { id: 224, name: 'sejneński', landId: 20, },
    { id: 225, name: 'siemiatycki', landId: 20, },
    { id: 226, name: 'sokólski', landId: 20, },
    { id: 227, name: 'suwalski', landId: 20, },
    { id: 228, name: 'wysokomazowiecki', landId: 20, },
    { id: 229, name: 'zambrowski', landId: 20, },
    { id: 230, name: 'm. Białystok', landId: 20, },
    { id: 231, name: 'm. Łomża', landId: 20, },
    { id: 232, name: 'm. Suwałki', landId: 20, },
    { id: 233, name: 'bytowski', landId: 22, },
    { id: 234, name: 'chojnicki', landId: 22, },
    { id: 235, name: 'człuchowski', landId: 22, },
    { id: 236, name: 'gdański', landId: 22, },
    { id: 237, name: 'kartuski', landId: 22, },
    { id: 238, name: 'kościerski', landId: 22, },
    { id: 239, name: 'kwidzyński', landId: 22, },
    { id: 240, name: 'lęborski', landId: 22, },
    { id: 241, name: 'malborski', landId: 22, },
    { id: 242, name: 'nowodworski', landId: 22, },
    { id: 243, name: 'pucki', landId: 22, },
    { id: 244, name: 'słupski', landId: 22, },
    { id: 245, name: 'starogardzki', landId: 22, },
    { id: 246, name: 'tczewski', landId: 22, },
    { id: 247, name: 'wejherowski', landId: 22, },
    { id: 248, name: 'sztumski', landId: 22, },
    { id: 249, name: 'm. Gdańsk', landId: 22, },
    { id: 250, name: 'm. Gdynia', landId: 22, },
    { id: 251, name: 'm. Słupsk', landId: 22, },
    { id: 252, name: 'm. Sopot', landId: 22, },
    { id: 253, name: 'będziński', landId: 24, },
    { id: 254, name: 'bielski', landId: 24, },
    { id: 255, name: 'cieszyński', landId: 24, },
    { id: 256, name: 'częstochowski', landId: 24, },
    { id: 257, name: 'gliwicki', landId: 24, },
    { id: 258, name: 'kłobucki', landId: 24, },
    { id: 259, name: 'lubliniecki', landId: 24, },
    { id: 260, name: 'mikołowski', landId: 24, },
    { id: 261, name: 'myszkowski', landId: 24, },
    { id: 262, name: 'pszczyński', landId: 24, },
    { id: 263, name: 'raciborski', landId: 24, },
    { id: 264, name: 'rybnicki', landId: 24, },
    { id: 265, name: 'tarnogórski', landId: 24, },
    { id: 266, name: 'bieruńsko-lędziński', landId: 24, },
    { id: 267, name: 'wodzisławski', landId: 24, },
    { id: 268, name: 'zawierciański', landId: 24, },
    { id: 269, name: 'żywiecki', landId: 24, },
    { id: 270, name: 'm. Bielsko-Biała', landId: 24, },
    { id: 271, name: 'm. Bytom', landId: 24, },
    { id: 272, name: 'm. Chorzów', landId: 24, },
    { id: 273, name: 'm. Częstochowa', landId: 24, },
    { id: 274, name: 'm. Dąbrowa Górnicza', landId: 24, },
    { id: 275, name: 'm. Gliwice', landId: 24, },
    { id: 276, name: 'm. Jastrzębie-Zdrój', landId: 24, },
    { id: 277, name: 'm. Jaworzno', landId: 24, },
    { id: 278, name: 'm. Katowice', landId: 24, },
    { id: 279, name: 'm. Mysłowice', landId: 24, },
    { id: 280, name: 'm. Piekary Śląskie', landId: 24, },
    { id: 281, name: 'm. Ruda Śląska', landId: 24, },
    { id: 282, name: 'm. Rybnik', landId: 24, },
    { id: 283, name: 'm. Siemianowice Śląskie', landId: 24, },
    { id: 284, name: 'm. Sosnowiec', landId: 24, },
    { id: 285, name: 'm. Świętochłowice', landId: 24, },
    { id: 286, name: 'm. Tychy', landId: 24, },
    { id: 287, name: 'm. Zabrze', landId: 24, },
    { id: 288, name: 'm. Żory', landId: 24, },
    { id: 289, name: 'buski', landId: 26, },
    { id: 290, name: 'jędrzejowski', landId: 26, },
    { id: 291, name: 'kazimierski', landId: 26, },
    { id: 292, name: 'kielecki', landId: 26, },
    { id: 293, name: 'konecki', landId: 26, },
    { id: 294, name: 'opatowski', landId: 26, },
    { id: 295, name: 'ostrowiecki', landId: 26, },
    { id: 296, name: 'pińczowski', landId: 26, },
    { id: 297, name: 'sandomierski', landId: 26, },
    { id: 298, name: 'skarżyski', landId: 26, },
    { id: 299, name: 'starachowicki', landId: 26, },
    { id: 300, name: 'staszowski', landId: 26, },
    { id: 301, name: 'włoszczowski', landId: 26, },
    { id: 302, name: 'm. Kielce', landId: 26, },
    { id: 303, name: 'bartoszycki', landId: 28, },
    { id: 304, name: 'braniewski', landId: 28, },
    { id: 305, name: 'działdowski', landId: 28, },
    { id: 306, name: 'elbląski', landId: 28, },
    { id: 307, name: 'ełcki', landId: 28, },
    { id: 308, name: 'giżycki', landId: 28, },
    { id: 309, name: 'iławski', landId: 28, },
    { id: 310, name: 'kętrzyński', landId: 28, },
    { id: 311, name: 'lidzbarski', landId: 28, },
    { id: 312, name: 'mrągowski', landId: 28, },
    { id: 313, name: 'nidzicki', landId: 28, },
    { id: 314, name: 'nowomiejski', landId: 28, },
    { id: 315, name: 'olecki', landId: 28, },
    { id: 316, name: 'olsztyński', landId: 28, },
    { id: 317, name: 'ostródzki', landId: 28, },
    { id: 318, name: 'piski', landId: 28, },
    { id: 319, name: 'szczycieński', landId: 28, },
    { id: 320, name: 'gołdapski', landId: 28, },
    { id: 321, name: 'węgorzewski', landId: 28, },
    { id: 322, name: 'm. Elbląg', landId: 28, },
    { id: 323, name: 'm. Olsztyn', landId: 28, },
    { id: 324, name: 'chodzieski', landId: 30, },
    { id: 325, name: 'czarnkowsko-trzcianecki', landId: 30, },
    { id: 326, name: 'gnieźnieński', landId: 30, },
    { id: 327, name: 'gostyński', landId: 30, },
    { id: 328, name: 'grodziski', landId: 30, },
    { id: 329, name: 'jarociński', landId: 30, },
    { id: 330, name: 'kaliski', landId: 30, },
    { id: 331, name: 'kępiński', landId: 30, },
    { id: 332, name: 'kolski', landId: 30, },
    { id: 333, name: 'koniński', landId: 30, },
    { id: 334, name: 'kościański', landId: 30, },
    { id: 335, name: 'krotoszyński', landId: 30, },
    { id: 336, name: 'leszczyński', landId: 30, },
    { id: 337, name: 'międzychodzki', landId: 30, },
    { id: 338, name: 'nowotomyski', landId: 30, },
    { id: 339, name: 'obornicki', landId: 30, },
    { id: 340, name: 'ostrowski', landId: 30, },
    { id: 341, name: 'ostrzeszowski', landId: 30, },
    { id: 342, name: 'pilski', landId: 30, },
    { id: 343, name: 'pleszewski', landId: 30, },
    { id: 344, name: 'poznański', landId: 30, },
    { id: 345, name: 'rawicki', landId: 30, },
    { id: 346, name: 'słupecki', landId: 30, },
    { id: 347, name: 'szamotulski', landId: 30, },
    { id: 348, name: 'średzki', landId: 30, },
    { id: 349, name: 'śremski', landId: 30, },
    { id: 350, name: 'turecki', landId: 30, },
    { id: 351, name: 'wągrowiecki', landId: 30, },
    { id: 352, name: 'wolsztyński', landId: 30, },
    { id: 353, name: 'wrzesiński', landId: 30, },
    { id: 354, name: 'złotowski', landId: 30, },
    { id: 355, name: 'm. Kalisz', landId: 30, },
    { id: 356, name: 'm. Konin', landId: 30, },
    { id: 357, name: 'm. Leszno', landId: 30, },
    { id: 358, name: 'm. Poznań', landId: 30, },
    { id: 359, name: 'białogardzki', landId: 32, },
    { id: 360, name: 'choszczeński', landId: 32, },
    { id: 361, name: 'drawski', landId: 32, },
    { id: 362, name: 'goleniowski', landId: 32, },
    { id: 363, name: 'gryficki', landId: 32, },
    { id: 364, name: 'gryfiński', landId: 32, },
    { id: 365, name: 'kamieński', landId: 32, },
    { id: 366, name: 'kołobrzeski', landId: 32, },
    { id: 367, name: 'koszaliński', landId: 32, },
    { id: 368, name: 'myśliborski', landId: 32, },
    { id: 369, name: 'policki', landId: 32, },
    { id: 370, name: 'pyrzycki', landId: 32, },
    { id: 371, name: 'sławieński', landId: 32, },
    { id: 372, name: 'stargardzki', landId: 32, },
    { id: 373, name: 'szczecinecki', landId: 32, },
    { id: 374, name: 'świdwiński', landId: 32, },
    { id: 375, name: 'wałecki', landId: 32, },
    { id: 376, name: 'łobeski', landId: 32, },
    { id: 377, name: 'm. Koszalin', landId: 32, },
    { id: 378, name: 'm. Szczecin', landId: 32, },
    { id: 379, name: 'm. Świnoujście', landId: 32, },
  ],
  findLand(googlePlace) {
    const landComponent = googlePlace.address_components.find(component => component.types.includes('administrative_area_level_1'));
    const landName = landComponent.long_name.toLowerCase().replace('województwo ', '');
    return this.lands.find(l =>
      l.name.toLowerCase().includes(landName.toLowerCase()) &&
      l.name.toLowerCase().indexOf(landName.toLowerCase()) < 4 // 'pomorskie' vs 'zachodniopomorskie'
    );
  },
  findDistrict(googlePlace, land) {
    const citiesDistricts = this.districts.filter(district => district.name.toLowerCase().startsWith('m.'));
    const districtComponent = googlePlace.address_components.find(component => component.types.includes('administrative_area_level_2'));
    const districtName = districtComponent.long_name.toLowerCase();

    const findPredicate = row =>
      (districtName.includes(row.name.toLowerCase()) && row.landId === land.id) ||
      (row.name.toLowerCase().includes(districtName));

    return citiesDistricts.find(findPredicate) || this.districts.find(findPredicate);
  },
  findPlace(googlePlace) {
    const land = this.findLand(googlePlace);
    const district = this.findDistrict(googlePlace, land);
    return { landId: land.id, districtId: district.id, };
  },

  findDistrictById(id) {
    return this.districts.find(row => row.id === id);
  },

  findLandById(id) {
    return this.lands.find(row => row.id === id);
  },
};
